import React from "react"
import Logo from "../../images/BaselineLogo.svg"
import Demo from "../../static/Demo.pdf"

export default function MobileFooter() {
  return (
    <footer className="text-black bg-off-white py-16 px-4 lg:hidden">
      <div className="flex flex-col">
        <div className="flex">
          <div className="flex-1 px-3">
            <ul className="mt-4 leading-loose">
              <li>
                <a href="/about-us/">About us</a>
              </li>
              <li>
                <a href="/contact-us/">Contact us</a>
              </li>
              <li>
                <a href="/terms-and-conditions/">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy/">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="flex-1 px-3">
            <ul className="mt-4 leading-loose">
              <li>
                <a
                  href="http://calendly.com/baseline-demo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Schedule a demo
                </a>
              </li>
              <li>
                <a href={Demo} target="_blank" rel="noreferrer">
                  Product demo
                </a>
              </li>
              <li className="text-dark-gray">Blog</li>
              <li className="text-dark-gray">Baseline community</li>
            </ul>
          </div>
        </div>
        <div className="flex-1 mt-12">
          <img src={Logo} alt="logo" />
          <p className="mt-5">Focus on people and build the best teams.</p>
          <p className="text-dark-gray font-light mt-12">
            All Rights Reserved © 2021 Baseline Team @N. A. K.
          </p>
        </div>
      </div>
    </footer>
  )
}
