import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import MobileFooter from "./MobileFooter"

export default function Layout({ children }) {
  return (
    <div className="font-sans">
      <Header />
      <main>{children}</main>
      <Footer />
      <MobileFooter />
    </div>
  )
}
