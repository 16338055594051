import React from "react"
import { Link } from "gatsby"
import { PrimaryButton } from "../Button"
import MobileHeader from "./MobileHeader"
import Logo from "../../images/BaselineLogo.svg"
import { useLocation } from "@reach/router"

export default function Header() {
  const location = useLocation()

  const currentPage = location.pathname
  let headerClassNames = "top-0 bg-primary-off-white"
  switch (currentPage) {
    case "/why-baseline/":
      headerClassNames = "top-0 bg-off-white"
      break
    case "/who-its-for/":
      headerClassNames = "top-0 bg-white"
      break
    case "/contact-us/":
      headerClassNames = "top-0 bg-white"
      break
    case "/privacy-policy/":
      headerClassNames = "top-0 bg-white"
      break
    case "/terms-and-conditions/":
      headerClassNames = "top-0 bg-white"
      break
    default:
      break
  }

  return (
    <header className={headerClassNames}>
      <div className="hidden h-20	lg:flex flex-col sm:flex-row justify-between items-center px-16">
        <div className="flex items-center text-2xl">
          <Link to="/">
            <img className="h-full w-auto" src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="flex items-center mt-4 sm:mt-0">
          <Link
            to="/why-baseline/"
            className="px-4 hover:text-dark-gray"
            activeStyle={{ color: "#888888" }}
          >
            Why Baseline
          </Link>
          <Link
            to="/who-its-for/"
            className="px-4 hover:text-dark-gray "
            activeStyle={{ color: "#888888" }}
          >
            Who it's for
          </Link>
          <Link
            to="/pricing/"
            className="px-4 hover:text-dark-gray"
            activeStyle={{ color: "#888888" }}
          >
            Pricing
          </Link>
          <Link
            to="/about-us/"
            className="px-4 hover:text-dark-gray"
            activeStyle={{ color: "#888888" }}
          >
            About us
          </Link>
          <a
            className="mx-4 hover:text-dark-gray"
            href="https://app.baselineteams.com/login"
          >
            Log in
          </a>
          <a className="ml-4" href="https://app.baselineteams.com/register">
            <PrimaryButton>Try for free</PrimaryButton>
          </a>
        </div>
      </div>
      <MobileHeader />
    </header>
  )
}
