import React from "react"

export const PrimaryButton = ({ children, onClick, full }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        full && "w-full"
      } bg-primary-blue py-2.5 px-8 rounded-full text-white hover:bg-primary-blue-hover focus:outline-none`}
    >
      {children}
    </button>
  )
}

export const SecondaryButton = ({ children, onClick, full }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        full && "w-full"
      } bg-green text-white rounded-full py-2.5 px-8 hover:bg-green-hover focus:outline-none`}
    >
      {children}
    </button>
  )
}

export const OutlineButton = ({ children, onClick, full }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        full && "w-full"
      } bg-white border-2 border-primary-blue rounded-full text-primary-blue py-2.5 px-8 hover: bg-off-white focus:outline-none`}
    >
      {children}
    </button>
  )
}
